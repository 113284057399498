<template>
  <v-container fluid class="px-2">
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>Elige el comercio que vas a Configurar o Editar</h1>
      </v-col>
    </v-row>
    <v-row class="pa-0 mt-6">
      <v-col
        v-for="business in filteredBusinesses"
        :key="business.id"
        cols="12"
        md="3"
        style="cursor: pointer"
      >
        <div
          class="d-flex flex-column align-center justify-space-between"
          @click="selectBusiness(business)"
        >
          <v-img
            :src="business.logo.original || ochoLogoPlaceholder"
            :lazy-src="business.logo.loading"
            alt="comercio"
            class="image-container"
          ></v-img>
          <br />
          <div class="subtitle-2">
            {{ business.shortName }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db, fb } from "@/firebase";
import { mapActions } from "vuex";

export default {
  name: "select-business",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      snackbar: false,
      snackbarText: "",
      businesses: [],
      ochoLogoPlaceholder:
        "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
    };
  },
  mounted() {
    this.$store.commit("setSearchTerm", "comercio");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.getBusinesses();
    this.loading = false;
  },
  methods: {
    ...mapActions(["addBusiness"]),
    selectBusiness(business) {
      this.addBusiness(business);
      this.snackbar = true;
      this.snackbarText = "Comercio seleccionado y activo en panel izquierdo";

      setTimeout(() => {
        let isOpen = document.getElementsByClassName("v-list-group--active");
        if(!isOpen.length) {
          document.getElementById("businessMenu").click();
        }
      }, 500);
    },
    getBusinesses() {
      let businessesCopy = [...this.$store.state.user.businesses];

      businessesCopy.forEach((_, index) => {
        if (index % 10 == 0) {
          let businessArray = businessesCopy.slice(index, index + 10);

          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", businessArray)
            .get()
            .then((businesses) => {
              businesses.forEach((business) => {
                let item = Object.assign(
                  { id: business.id, ".key": business.id },
                  business.data()
                );
                this.businesses.push(item);
              });
            });
        }
      });
    },
  },
  computed: {
    filteredBusinesses() {
      if (this.$store.state.search) {
        return this.businesses.filter((item) =>
          item.shortName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.$store.state.search
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        );
      } else {
        return this.businesses;
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 2rem;
}
</style>
